import React, { Component } from 'react';
import "./twoColumn.css";
import HomeCircle from "./homeCircle";

class HomeTwoColumn extends Component {
    render() {
        return (
            <div className="row fullTwoColumn flex-column-reverse flex-lg-row">
                <div className="col-lg-6 verticleCenter">
                    <div className="titleText align-self-center ">
                        <div className="subTitle">
                            Our Mission:
                        </div>
                        <p style={{ fontWeight: '300' }}>
                            To educate our friends on software's role in society and responsibly build the future of New York City.
                        </p>

                    </div>
                </div>
                <div className="col-lg-6">
                    <HomeCircle className="circleImage" />
                </div>
            </div>
        );
    }
}

export default HomeTwoColumn;