import React, { useState, useRef } from "react";

import "./DropDown.css";

function DropDown(props) {

    const [setActive, setActiveState] = useState("");
    const [setHeight, setHeightState] = useState("0px");
    const [setButton, setButtonState] = useState("+");


    const content = useRef(null);

    function toggleContent() {
        setActiveState(setActive === "" ? "active" : "");
        setHeightState(setActive === "active" ? "0px" : `${content.current.scrollHeight}px`);
        setButtonState(setActive === "active" ? "+" : "-");
    }


    return (
        <div onKeyDown={() => { toggleContent() }} onClick={() => { toggleContent() }} style={{ cursor: "pointer", backgroundColor: props.color }}>
            <div className="dropDownQuestion titleFont" style={{ fontSize: "2.3em", margin: `0 auto`, maxWidth: 1000, padding: `0 1rem` }}>
                <div className="row" style={{ paddingTop: '1em', paddingBottom: '1em' }}>
                    <div className="col-11">
                        <div style={{ display: "inline-block", paddingLeft: '0px', paddingRight: '0px' }}>
                            {props.question}
                        </div>
                    </div>
                    <div className="col-1 my-auto" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                        <button onKeyDown={() => { toggleContent() }} onClick={() => { toggleContent() }} className="btn dropDownButton" style={{ float: "right" }}>
                            {`${setButton}`}
                        </button>
                    </div>

                </div>
            </div>
            <div className="dropDownContent" ref={content} style={{ margin: `0 auto`, maxWidth: 1000, padding: `0 1rem`, overflow: 'hidden', maxHeight: `${setHeight}` }} >
                <div className="row columnReverse" style={{ flexDirection: props.reverse === "true" ? 'row' : 'row-reverse' }}>
                    <div style={{ paddingTop: '2em', paddingBottom: '2em' }} className="col-sm-6 justify-content-center d-flex">
                        <div style={{ alignSelf: 'center' }}>
                            {props.answer}
                        </div>


                    </div>
                    <div style={{ paddingTop: '2em', paddingBottom: '2em' }} className="col-sm-6">
                        <img alt={props.alt} className="dropdownImage" style={{ width: '100%' }} src={props.image} />
                    </div>
                </div>
            </div>
        </div>
    );

}

export default DropDown;