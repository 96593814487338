import React, { Component } from 'react';
import HomeTwoColumn from "../components/homeTwoColumn";
import Footer from "../components/Footer";
import DropDown from "../components/DropDown";
import Layout from '../components/layout';
import image from "../images/skyline.jpg";
import wave from "../images/wave.svg";
import favicon from "../images/favicon.ico";
import { Helmet } from "react-helmet";
import software from "../images/software.jpg";
import innovation from "../images/innovation.jpg";
import pandemic from "../images/pandemic.jpg";
import transportation from "../images/transportation.jpg";
import stores from "../images/stores.jpg";
import zoom from "../images/zoom.jpg";
import diversity from "../images/diversity.jpg"
import green from "../images/green.jpg";
import social from "../images/social.jpg";
import theory from "../images/theory.jpg";
import kardashev from "../images/kardashev.jpg";
import ai from "../images/ai.jpg";
import surveillance from "../images/surveillance.jpg";
import memes from "../images/memes.jpg";
import crowdfunding from "../images/crowdfunding.jpg";
import pets from "../images/pets.jpg";
import recreational from "../images/recreational.jpg";
import security from "../images/security.jpg";
import biological from "../images/biological.jpg";
import nanotechnology from "../images/nanotechnology.jpg";
import financial from "../images/financial.jpg";
import "./education.css";

class education extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Education</title>
                    <link rel="canonical" href="http://www.newnewyorkcapital.com/education" />
                    <link rel="icon" href={favicon} />
                </Helmet>
                <Layout>
                    <HomeTwoColumn />
                </Layout>

                <div style={{ marginBottom: '-1px', width: "100%", height: "5em", background: `url(${wave})`, backgroundSize: 'cover' }}></div>
                <DropDown question="New New York Capital"
                    answer="We founded New New York Capital in January 2021 on a mission to inform our friends of software’s role in society and advocate for responsibly building the future of New York City. We don't provide investment advice and will blog more on each plank of our platform. Our opinions are our own and do not reflect those of our affiliates."
                    image={image}
                    alt="New York City skyline"
                    color="rgba(0, 182, 222, 1)" />
                <DropDown question="Software and humanity"
                    reverse="true"
                    alt="Man looking at software on a computer"
                    answer="Software affects everything including concepts we can’t see or feel. Some humans will get to keep making decisions in the future, but soon we'll see society run completely on software. We want to learn more and share our findings so we don't get left behind and better understand software’s role in our lives."
                    image={software}
                    color="rgba(0, 182, 222, 0.95)" />
                <DropDown question="Innovation, incubators, and venture capital "
                    answer="From Wall Street to Silicon Valley: some bet it all to change the world, some advise others on how, and some diversify their efforts to improve their odds of making a difference. We can learn from the best of many disciplines to do things smarter and safer for the future."
                    alt="Crumpled up paper next to a paper airplane"
                    image={innovation}
                    color="rgba(0, 182, 222, 0.9)" />
                <DropDown question="The pandemic state of New York"
                    reverse="true"
                    alt="Movie theater sign which reads the world is temporarily closed"
                    answer="Nobody at the 2020 Super Tuesday debate wanted to talk about Covid-19 as it spread across the world. Weeks later, the pandemic ravaged New York City. Residents moved out, many lost loved ones, and we all had to second guess what the future would hold. We’re comfortable telling our friends that things won't look like they did in 2019."
                    image={pandemic}
                    color="rgba(0, 182, 222, 0.85)" />
                <DropDown question="Smart city infrastructure and transportation"
                    answer="New York wasn't founded as a smart city but its cultures, populations, and business activities have all shifted over the City’s history. Public and private investors recognize that the City is uniquely positioned and long overdue for major software updates."
                    alt="lines of light signifying movement througha city stree"
                    image={transportation}
                    color="rgba(0, 182, 222, 0.8)" />
                <DropDown question="Stores, restaurants, bars, and public spaces"
                    alt="Placemat with glass, silverwear and napkin in an empty restaurant"
                    reverse="true"
                    answer="New York’s physical retail and hospitality spaces may have suffered the most from Covid-19. They’ll recover as safety regulatiions allow, but we'll continue to see various aspects of these spaces go digital and remote as we won't need to leave our homes as often."
                    image={stores}
                    color="rgba(0, 182, 222, 0.75)" />
                <DropDown question="Zoom Universities "
                    answer="Private and public schools have investment decisions to make across academics, campuses, student life, and financial securities. The importance of student socialization can’t be understated but schools have operated during the pandemic with varying degrees of in-person interaction. Let’s download classroom content and spend more time on extracurriculars."
                    alt="Computer showing a zoom meeting sitting on a desk next to a coffee cup."
                    image={zoom}
                    color="rgba(0, 182, 222, 0.7)" />
                <DropDown question="The Green New Deal and tech policy "
                    reverse="true"
                    alt="Dam with greenery and river flowing by"
                    answer="The future can be greener, cleaner, and safer. The Biden Administration is prepared to police those changing the world and push for development of the right technologies, but policymakers have campaign dollars to win and puppeteers to please. We will respect the law and understand that it has significant limitations in protecting our people. "
                    image={green}
                    color="rgba(0, 182, 222, 0.65)" />

                <DropDown question="Social issues, demographics, and diversity "
                    alt="graphic street art of two hands with multiple colors"
                    answer="While solving some issues, software will inevitably create new ones. It will also change the makeup of society and rearrange our priorities. As software and society evolve in tandem we’ll want to hear new voices speak and in ways we haven't heard before."
                    image={diversity}
                    color="rgba(0, 182, 222, 0.6)" />

                <DropDown question="Theory of Everything, computers, and simulations"
                    reverse="true"
                    alt="small group examining large screen showing a simulation"
                    answer="Physicists and computer scientists believe that we can represent the universes with computer simulations. They're working to build faster computers and find solutions to this complex problem. Whether it's true or likely, we need to understand where we came from and where we're going."
                    image={theory}
                    color="rgba(0, 182, 222, 0.55)" />
                <DropDown question="Kardashev scale and space exploration"
                    alt="the milky way galaxy"
                    answer="As humans on earth, it’s possible that we could advance on the scale from a Type 0.75 civilization to Type 1 during our lives. Over time our technologies will improve but our energy consumption will also rise exponentially. We're unlikely to discover aliens soon but exploring could help us progress as a civilization, even in NYC."
                    image={kardashev}
                    color="rgba(0, 182, 222, 0.5)" />
                <DropDown question="Artificial intelligence and automation"
                    alt="man holding a post-it that has A.I. written on it"
                    reverse="true"
                    answer="Software will replace and outsource certain activities, but it'll also perform faster and more accurately than we can by ourselves. Some of us should retrain and educate ourselves for new opportunities that others won't have."
                    image={ai}
                    color="rgba(0, 182, 222, 0.45)" />
                <DropDown question="Surveillance capitalism, digital id, and democracy"
                    answer="Privacy and security matter but even if we live under a rock, there are institutions watching, tracking, and recording us. We'll exist in the clouds (computers and the web that is) at least until the sun implodes."
                    alt="camera spray painted onto a wall"
                    image={surveillance}
                    color="rgba(0, 182, 222, 0.4)" />
                <DropDown question="Social media and fake news"
                    reverse="true"
                    alt="line of people using their phones"
                    answer="There is no truth without consensus on principles and logic to expand them. Too many fail to understand what they’re reading, who they’re listening to, or what their time and money do. The most objective sources will convince us to buy or become their products unless we opt out or educate ourselves."
                    image={social}
                    color="rgba(0, 182, 222, 0.35)" />
                <DropDown question="Memes and the viral internet"
                    answer="The new culture is online. Younger generations dream of becoming Youtubers, Tiktok stars, Instagram influencers, and Twitch streamers. These titles came into existence recently and are accessible to nearly anyone with a smartphone or computer. The internet will spawn more opportunities that we haven't even thought of yet."
                    image={memes}
                    alt="Child in darkness looking at a computer screen"
                    color="rgba(0, 182, 222, 0.3)" />
                <DropDown question="Crowdfunding and monetization"
                    reverse="true"
                    alt="dollar signs with jittery effect"
                    answer="Democracy, capitalism, and the internet work together to turn some of the newest visions into reality. Artists, policy junkies, inventors, and more have found ways to thrive in a marketplace that recognizes previously undiscovered talents. "
                    image={crowdfunding}
                    color="rgba(0, 182, 222, 0.25)" />
                <DropDown question="Pets"
                    answer="We've been best friends forever and our relationships with our pets will only grow stronger. Advances in software will raise standards of living for animals that already rule the world but we'll have to look out for some of our less fortunate friends. "
                    image={pets}
                    alt="man hugging golden retreiver"
                    color="rgba(0, 182, 222, 0.2)" />
                <DropDown question="Recreational and electronic sports"
                    reverse="true"
                    alt="desk with laptop notepad and headphones"
                    answer="We don’t have the same hobbies as our parents as we continue spending more time online and less outdoors. Ubiquitous digital interfaces capture more of our attention than ever before though we might spend more holidays with our parents if we can virtually. "
                    image={recreational}
                    color="rgba(0, 182, 222, 0.15)" />
                <DropDown question="Insurance, security, and cyberwarfare"
                    answer="Physical and computer security are quickly improving but so are the hackers. This arms race is happening on a global scale so we have to protect ourselves with software and other clever innovations."
                    image={security}
                    alt="laptop half closed with the screen on lighting the keyboard"
                    color="rgba(0, 182, 222, 0.1)" />
                <DropDown question="Biological enhancements, medical devices, and medicine"
                    reverse="true"
                    alt="doctor using a smartphone"
                    answer="Nobody should want to live forever because our minds and bodies deteriorate over time. Medicine will extend average life expectancy but that doesn’t mean we’ll be healthier. Over time, the medical and scientific communities can responsibly uncover many unknowns with the assistance of software. "
                    image={biological}
                    color="rgba(0, 182, 222, 0.1)" />
                <DropDown question="Nanotechnology and 3D printing"
                    answer="We've seen slivers of these software applications’ potential in Hollywood action films. While still expensive and in early development, these technologies will continue to revolutionize traditional design and methods in manufacturing, real estate development, food production, and more."
                    image={nanotechnology}
                    alt="3D printer building an object"
                    color="rgba(0, 182, 222, 0.05)" />
                <DropDown question="Financial markets, assets, and technology"
                    reverse="true"
                    answer="Traditional asset classes are easier to understand but most of the potential has been competed away. Software, however, has enabled some of the most interesting emerging asset classes and will be a necessary component of financial innovation."
                    image={financial}
                    alt="newspaper showing the price of the dow jones"
                    color="rgba(0, 182, 222, 0.0)" />
                <Footer />
            </div >
        );
    }
}

export default education;
